<template>
    <div>
        <div
            class="form-msg-wrapper"
            :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']"
        >
            <p>{{ formErrorContent }}</p>
        </div>
        <h2 class="title">{{ $t('login.title') }}</h2>
        <p class="description">{{ $t('login.description') }}</p>
        <form @submit.prevent="createRecaptchaToken">
            <div class="field">
                <input
                    id="username"
                    type="text"
                    class="inputfield w-full"
                    :class="[errors.USERNAME.mandatory ? 'error' : '']"
                    :placeholder="
                        errors.USERNAME.mandatory
                            ? $t('miscellaneous.error_msgs.required')
                            : $t('miscellaneous.placeholders.username')
                    "
                    v-model="LOGIN_FORM.USERNAME"
                />
            </div>
            <div class="field">
                <input
                    id="password"
                    type="password"
                    class="inputfield w-full"
                    :class="[errors.PASSWORD.mandatory ? 'error' : '']"
                    :placeholder="
                        errors.PASSWORD.mandatory
                            ? $t('miscellaneous.error_msgs.required')
                            : $t('miscellaneous.placeholders.password')
                    "
                    v-model="LOGIN_FORM.PASSWORD"
                />
            </div>
            <a
                href="#"
                class="forgot-password"
                @click="
                    () => {
                        resetPasswordFormToggle('open')
                        resetFormErrors()
                    }
                "
                >{{ $t('login.reset_password') }}</a
            >
            <div class="field login-btn">
                <ButtonPV label="Einloggen" class="button-raised w-full primary" type="submit" />
            </div>

            <div class="account-creation">
                <div>
                    <b>{{ $t('login.signup_option.message_1') }}</b>
                </div>
                <ul>
                    <li>
                        {{ $t('login.signup_option.option_1_pre') }}
                        <a @click="$router.push('/')" class="signup-nav">{{
                            $t('miscellaneous.here')
                        }}</a>
                        {{ $t('login.signup_option.option_1_post') }}
                    </li>
                </ul>
                <div>
                    <b>{{ $t('login.signup_option.message_2') }}</b>
                </div>
                <ul>
                    <li>
                        {{ $t('login.signup_option.option_2_pre') }}
                        <a @click="$router.push('verify-email')" class="signup-nav">{{
                            $t('miscellaneous.here')
                        }}</a>
                        {{ $t('login.signup_option.option_2_post') }}
                    </li>
                </ul>
            </div>
        </form>

        <div class="reset-password-wrapper">
            <div
                class="form-msg-wrapper"
                :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']"
            >
                <p>{{ formErrorContent }}</p>
            </div>
            <h2 class="title">
                {{ $t('password_reset.reset_title') }}
            </h2>
            <p class="description">
                {{ $t('password_reset.reset_password_long') }}
            </p>
            <form @submit.prevent="submitResetPassword">
                <div class="field">
                    <input
                        id="email"
                        type="text"
                        class="inputfield w-full"
                        :class="[errors.EMAIL.mandatory ? 'error' : '']"
                        :placeholder="
                            errors.EMAIL.mandatory
                                ? $t('miscellaneous.error_msgs.required')
                                : $t('password_reset.your_email')
                        "
                        v-model="RESET_PASSWORD_FORM.EMAIL"
                        :disabled="isDisable"
                    />
                </div>
                <div class="field login-btn">
                    <ButtonPV
                        :label="$t('password_reset.send_instructions')"
                        class="button-raised w-full primary"
                        type="submit"
                        :disabled="isDisable"
                    />
                </div>
                <!-- display create new account when the email is unknown -->
                <div class="field" v-if="isEmailUnknown">
                    <ButtonPV
                        :label="$t('login.create_new_account')"
                        class="button-raised w-full primary"
                        @click="$router.push('verify-email')"
                    />
                </div>
                <a
                    href="#"
                    class="forgot-password"
                    @click="
                        () => {
                            resetPasswordFormToggle('close')
                            resetFormErrors()
                        }
                    "
                    >{{ $t('password_reset.back_to_login') }}</a
                >
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, ref } from 'vue'
import utils from '@/end-user-portal/shared/mixins/utils'
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import NProgress from 'nprogress'
import store from '@/store'
import { useReCaptcha } from 'vue-recaptcha-v3'
import commonVariables from '@/shared/Variables'
import { useI18n } from 'vue3-i18n'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'

export default defineComponent({
    setup() {
        const reCaptcha = useReCaptcha()
        const executeRecaptcha = reCaptcha?.executeRecaptcha
        const recaptchaLoaded = reCaptcha?.recaptchaLoaded
        const currentLanguage = ref(process.env.VUE_APP_LANGUAGE)
        const isDisable = ref(false)
        const accessToken = localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN)
            ? localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.TOKEN)
            : ''
        if (accessToken !== '') {
            AuthenticationService.getProfileDetails()
                .then((response) => {
                    const profileDetails = response?.data
                    store.commit('SET_PROFILE_DETAILS', profileDetails)
                    router.push({ name: 'RegistrationsView' })
                })
                .catch((error: { response: { status: number } }) => {
                    if (error.response?.status !== 401) {
                        localStorage.clear()
                        router.push({ name: 'LoginView' })
                    }
                })
        } else {
            localStorage.clear()
            router.push({ name: 'LoginView' })
        }
        const isError = ref(false)
        const isFormMsg = ref(false)
        const formErrorContent = ref('')
        const LOGIN_FORM = ref({
            USERNAME: '',
            PASSWORD: '',
        })
        const isEmailUnknown = ref(false)
        const RESET_PASSWORD_FORM = ref({
            EMAIL: '',
        })
        const errors: Ref<ErrorObjectDTO> = ref({
            USERNAME: { mandatory: false },
            PASSWORD: { mandatory: false },
            EMAIL: { mandatory: false },
        })
        const { t } = useI18n()
        const validateForm = (formType: string) => {
            errors.value = {
                USERNAME: { mandatory: false },
                PASSWORD: { mandatory: false },
                EMAIL: { mandatory: false },
            }
            isError.value = false
            isFormMsg.value = false
            formErrorContent.value = ''
            if (formType === 'loginForm') {
                errors.value.USERNAME.mandatory = utils.validateFields(
                    LOGIN_FORM.value.USERNAME,
                    'string'
                )
                errors.value.PASSWORD.mandatory = utils.validateFields(
                    LOGIN_FORM.value.PASSWORD,
                    'string'
                )
            } else if (formType === 'resetPasswordForm') {
                errors.value.EMAIL.mandatory = utils.validateFields(
                    RESET_PASSWORD_FORM.value.EMAIL,
                    'string'
                )
            }
        }
        const submitLogin = async (recaptchaToken: string) => {
            const data = {
                username: LOGIN_FORM.value.USERNAME.toLowerCase(),
                password: LOGIN_FORM.value.PASSWORD,
                recaptcha: recaptchaToken,
            }
            AuthenticationService.setAuthentication(data)
                .then(
                    (response: {
                        data: {
                            tokens: { access: string; refresh: string }
                            recaptcha_extra_check: boolean
                            user_id: number
                        }
                    }) => {
                        if (response.data.recaptcha_extra_check) {
                            router.push({
                                name: 'RecaptchaOtpFlow',
                                params: { userId: response.data.user_id },
                            })
                        } else {
                            localStorage.setItem(
                                commonVariables.LOCAL_STORAGE_ITEMS.TOKEN,
                                response?.data?.tokens.access
                            )
                            localStorage.setItem(
                                commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN,
                                response?.data?.tokens.refresh
                            )
                            router.push({ name: 'RegistrationsView' })
                        }
                    }
                )
                .catch(
                    (error: {
                        response: { data: { details: string; detail: string }; status: number }
                    }) => {
                        if (
                            error.response?.data?.details !== '' &&
                            error.response?.status === 401
                        ) {
                            NProgress.done()
                            isError.value = true
                            isFormMsg.value = true
                            if (error.response.data.detail === 'Invalid recaptcha') {
                                formErrorContent.value = t('signup.recaptcha_error')
                            } else {
                                formErrorContent.value = t('signup.credential_error')
                            }
                        }
                    }
                )
        }
        const recaptcha = async () => {
            if (recaptchaLoaded) await recaptchaLoaded()
            if (executeRecaptcha) {
                const token = await executeRecaptcha('login')
                return token
            } else return ''
        }

        // if the recaptcha token created successfully, triger the reset password API with token attached
        const getRecaptchaVerified = async (token: string) => {
            try {
                submitLogin(token)
            } catch (err) {
                console.log('Error while reading recaptcha', err)
            }
        }

        // if the form is valid, creating recaptcha token
        const createRecaptchaToken = async () => {
            validateForm('loginForm')
            if (utils.hasErrors(errors.value)) {
                return
            }
            const token = await recaptcha()
            if (token) await getRecaptchaVerified(token)
        }

        const resetFormErrors = () => {
            isEmailUnknown.value = false
            isError.value = false
            isFormMsg.value = false
            formErrorContent.value = ''
        }

        const submitResetPassword = async () => {
            validateForm('resetPasswordForm')
            resetFormErrors()
            if (utils.hasErrors(errors.value)) {
                return
            }
            const data = {
                email: RESET_PASSWORD_FORM.value.EMAIL.trim().toLowerCase(),
            }
            isDisable.value = false
            AuthenticationService.resetPasswordVerifyEmail(data)
                .then((response: { data: { success: string | string[] } }) => {
                    if (
                        response.data?.success.includes(
                            'We have sent you a link to reset your password'
                        )
                    ) {
                        resetPasswordFormToggle('close')
                        localStorage.clear()
                        isDisable.value = true
                        isError.value = false
                        isFormMsg.value = true
                        formErrorContent.value = t('password_reset.email_submit_confirm')
                    }
                })
                .catch(
                    (error: {
                        response: {
                            status: number
                            data: {
                                error: string | string[] | undefined
                                email: (string | string[])[]
                                error_code: string
                            }
                        }
                    }) => {
                        NProgress.done()
                        isError.value = true
                        isFormMsg.value = true
                        if (error.response.data.error_code) {
                            isEmailUnknown.value = error.response.data.error_code === 'EMAIL001'
                            formErrorContent.value = t(
                                utils.getErrorMessageTranslationKey(error.response.data.error_code)
                            )
                        } else {
                            formErrorContent.value = t('miscellaneous.error_msgs.server_error')
                        }
                        console.error('password reset email send error')
                    }
                )
        }
        const resetPasswordFormToggle = (type: string) => {
            const resetPasswordWrapper = document.querySelector('.reset-password-wrapper')
            if (resetPasswordWrapper && type === 'open') {
                errors.value.USERNAME.mandatory = utils.validateFields(
                    LOGIN_FORM.value.USERNAME,
                    ''
                )
                errors.value.PASSWORD.mandatory = utils.validateFields(
                    LOGIN_FORM.value.PASSWORD,
                    ''
                )
                resetPasswordWrapper.classList.add('opened')
            } else if (resetPasswordWrapper && type === 'close') {
                errors.value.EMAIL.mandatory = utils.validateFields(
                    RESET_PASSWORD_FORM.value.EMAIL,
                    ''
                )
                resetPasswordWrapper.classList.remove('opened')
            }
        }

        return {
            LOGIN_FORM,
            RESET_PASSWORD_FORM,
            errors,
            isFormMsg,
            formErrorContent,
            isError,
            currentLanguage,
            isDisable,
            createRecaptchaToken,
            resetPasswordFormToggle,
            submitResetPassword,
            isEmailUnknown,
            resetFormErrors,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
@import '@/end-user-portal/assets/styles/login';
.account-creation {
    ul {
        margin-top: 0 !important;
    }
}
</style>
